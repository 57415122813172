import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Logout } from '../../auth/store/actions/auth.actions';
import { State } from '../../auth/store';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(private store: Store<State>) {}

  handleError(response: HttpErrorResponse, _url: string): Observable<HttpEvent<unknown>> {
    // console.log('handleError response', response);
    // console.log('handleError url', url);

    switch (response.status) {
      case 0: {
        const resp = response.error;
        return throwError(
          () =>
            new Error({
              ...resp,
              message: 'No internet connection',
            })
        );
      }

      case 302: {
        // handle session timeout
        this.store.dispatch(Logout());
        const resp = response.error;
        return throwError(
          () =>
            new Error({
              ...resp,
              message: 'Session timeout. Please, try again.',
            })
        );
      }

      case 401:
        this.store.dispatch(Logout());
        return throwError(() => response);

      default: {
        return throwError(() => response);
      }
    }
  }
}
