<section>
  <img src="assets/logos/login_logo.svg" alt="Shipa Cerebro" />
  <form [formGroup]="loginFormGroup" (ngSubmit)="submit()">
    <h2>Good to see you</h2>
    <p>Enter your details and let’s dive into it.</p>

    <app-message
      *ngIf="(error$ | async) || (profileError$ | async)"
      [type]="'error'"
      [message]="(error$ | async)?.message || (profileError$ | async)?.message || 'Something went wrong'"
    ></app-message>

    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" placeholder="Email" required />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" placeholder="Password" required [type]="passFieldType" />
      <mat-icon matSuffix (click)="toggleShowPass();" [svgIcon]="showPassword ? 'hide' : 'show' "></mat-icon>
    </mat-form-field>

    <button mat-raised-button color="accent" type="submit">Sign in</button>

    <a routerLink="/forgot-password">Forgot password?</a>
  </form>
</section>

<div *ngIf="loading$ | async" class="loader-overlay transparent">
  <app-loader class="loader--large"></app-loader>
</div>
