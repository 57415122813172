import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Hub } from '../interfaces/hub.interface';
import { Subsidiary } from '../interfaces/subsidiary.interface';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Permission } from '@general-data/interfaces/permission.interface';
import { CancelReason } from '@general-data/interfaces/cancel-reason.interface';
import { GenericScanResp } from '@core/interfaces/scan-response.interface';
import { Country } from '@general-data/interfaces/country.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  public getHubs(): Observable<Hub[]> {
    return this.http
      .get<Hub[]>(`${environment.api}/services/cerebro/hubs`)
      .pipe(map((hubs) => hubs.sort((a, b) => (a.country > b.country ? -1 : 1))));
  }

  public getSubsidiaries(): Observable<Subsidiary[]> {
    return this.http
      .get<{ subsidiaries: Subsidiary[] }>(`${environment.api}/services/meta/dictionaries?type=subsidiaries`)
      .pipe(map((resp) => resp.subsidiaries));
  }

  public getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(`${environment.api}/services/cerebro/management/permissions`);
  }

  public getCancelReasons(): Observable<CancelReason[]> {
    return this.http
      .get<any>(`${environment.api}/services/cerebro/meta?types=cancelReason`)
      .pipe(
        map(
          (response) =>
            <CancelReason[]>Object.values(response.cancelReason).sort((a: any, b: any) => a.name.localeCompare(b.name))
        )
      );
  }

  public getOrderTypes(): Observable<string[]> {
    return this.http
      .get<{ type: string[] }>(`${environment.api}/services/cerebro/meta?types=type`)
      .pipe(map((response) => response.type));
  }

  public getOrderCategories(): Observable<string[]> {
    return this.http
      .get<{ category: string[] }>(`${environment.api}/services/cerebro/meta?types=category`)
      .pipe(map((response) => response.category));
  }

  public searchOrder(trackingNumber: string): Observable<GenericScanResp> {
    return this.http.get<GenericScanResp>(
      `${environment.api}/services/cerebro/orders/search?identifier=${trackingNumber}`
    );
  }

  public getCountries(): Observable<Country[]> {
    return this.http
      .get<any[]>(`${environment.api}/services/meta/dictionaries?type=countries`)
      .pipe(map((resp: any) => Object.values(resp.countries)));
  }

  public getCustomerActionReasons(): Observable<string[]> {
    return this.http
      .get<any>(`${environment.api}/services/meta/dictionaries?type=customerActionReasons`)
      .pipe(map((resp) => resp.customerActionReasons));
  }
}
