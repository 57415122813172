import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { HttpErrorService } from '../services/http-error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private httpErrorService: HttpErrorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return (
      next
        .handle(req)
        .pipe(
          filter((event: any) => event instanceof HttpResponse),
          switchMap((res: HttpResponse<any>) => {
            // TODO: refine this to catch all possible errors
            if (req.url.startsWith(environment.api) && res.body && res.body.code && res.body.message) {
              return throwError(() => res.body);
            }
            return of(res);
          })
        )
        // .pipe(catchError((res: HttpErrorResponse) => throwError(res.error)));
        // .pipe(catchError((res: HttpErrorResponse | HttpResponse<any>) => this.httpErrorService.handleError(res, req.url)));
        .pipe(catchError((res: HttpErrorResponse) => this.httpErrorService.handleError(res, req.url)))
    );
  }
}
