import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State } from '../store';
import { Store } from '@ngrx/store';
import { selectAuthUserToken } from '../store/selectors/session.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthRequiredGuard {
  constructor(
    private store: Store<State>,
    private router: Router
  ) {}

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  public canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  public canLoad(
    _route: Route,
    _segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    return this.store.select(selectAuthUserToken).pipe(
      map((token: string | null) => !!token),
      tap((isAuth: boolean) => !isAuth && this.router.navigateByUrl('/'))
      // tap((isAuth: boolean) => !isAuth && this.router.navigateByUrl('/sing-in'))
    );
  }
}
