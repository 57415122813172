import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store';
import { DataEffects } from './store/effects/data.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('general-data', reducers), EffectsModule.forFeature([DataEffects])],
})
export class GeneralDataModule {}
