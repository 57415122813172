<!-- <div *ngIf="loading$ | async" class="loader-overlay transparent">
  <app-loader class="loader--large"></app-loader>
</div> -->
<div *ngIf="loading" class="loader-overlay transparent">
  <mat-progress-bar mode="query" color="accent" class="progress-bar"></mat-progress-bar>
</div>

<mat-sidenav-container class="sidenav-container" [hasBackdrop]="sidebar.opened" (backdropClick)="onSidenavClose()">
  <!-- Sidebars -->
  <mat-sidenav #sidebar position="end" (openedChange)="onSidebarChange($event)">
    <ng-template #sideBarContainer></ng-template>
  </mat-sidenav>

  <app-header></app-header>

  <!-- Content (Header + menu + main content) -->
  <router-outlet></router-outlet>
</mat-sidenav-container>
