<section>
  <a routerLink="/"><img src="assets/logos/login_logo.svg" alt="Shipa Cerebro" /></a>
  <form (ngSubmit)="submit()">
    <h2>{{ texts.title }}</h2>
    <p>
      {{ texts.description }}
      <span *ngIf="verificationSentTo$ | async as verificationSentTo">{{ '\{{' + verificationSentTo + '\}}' }}</span>
    </p>

    <app-message
      *ngIf="(error$ | async)"
      [type]="'error'"
      [message]="(error$ | async)?.message || 'Something went wrong'"
    ></app-message>

    <mat-form-field *ngIf="texts.emailField">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="email" placeholder="Email" required />
    </mat-form-field>

    <mat-form-field *ngIf="texts.codeField">
      <mat-label>Verification code</mat-label>
      <input matInput [formControl]="code" placeholder="Verification code" required />
    </mat-form-field>

    <div *ngIf="texts.passwordFields" [formGroup]="newPasswordFormGroup">
      <mat-form-field>
        <mat-label>New password</mat-label>
        <input matInput formControlName="password" placeholder="New password" required [type]="passFieldTypeOne" />
        <mat-icon matSuffix (click)="toggleShowPassOne();" [svgIcon]="showPasswordOne ? 'show' : 'hide'"></mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Confirm new password</mat-label>
        <input
          matInput
          formControlName="confirmPassword"
          placeholder="Confirm new password"
          required
          [type]="passFieldTypeTwo"
        />
        <mat-icon matSuffix (click)="toggleShowPassTwo();" [svgIcon]="showPasswordTwo ? 'show' : 'hide'"></mat-icon>
      </mat-form-field>
      <mat-error *ngIf="newPasswordFormGroup.get('confirmPassword')?.hasError('not_matching')">
        The password doesn't match!
      </mat-error>
    </div>

    <button mat-raised-button color="accent" type="submit">{{ texts.buttonText }}</button>

    <a
      *ngIf="texts.resendButton"
      href="#"
      (click)="reSendCode($event)"
      [class.disabled]="(timeRemaining$ | async) ? true : null"
      >Resend {{ (timeRemaining$ | async) ? '(' + (timeRemaining$ | async | date:'mm:ss') + ')' : '' }}</a
    >
  </form>
</section>

<div *ngIf="loading$ | async" class="loader-overlay transparent">
  <app-loader class="loader--large"></app-loader>
</div>
