import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InitForgotPassword, ResetPassword, VerifyCode } from '@auth/store/actions/auth.actions';
import { selectLoginError, selectLoginLoading, selectVerificationSentTo } from '@auth/store/selectors/login.selectors';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, takeUntil, takeWhile, timer } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public readonly error$: Observable<Error | null>;
  public readonly loading$: Observable<boolean>;
  public readonly verificationSentTo$: Observable<string | null>;
  public timeRemaining$: Observable<number> | null = null;
  public email = new FormControl('', { nonNullable: true, validators: [Validators.required] });
  public code = new FormControl('', { nonNullable: true, validators: [Validators.required] });
  public resetPasswordtexts = {
    title: 'Reset password',
    description: 'Enter your email address and we will send you a code to reset your password.',
    buttonText: 'Reset password',
    emailField: true,
    codeField: false,
    resendButton: false,
    passwordFields: false,
  };
  public codeVerificationTexts = {
    title: 'Verification code',
    description: 'SMS with a verification code has been sent to ',
    buttonText: 'Verify code',
    emailField: false,
    codeField: true,
    resendButton: true,
    passwordFields: false,
  };
  public setNewPasswordTexts = {
    title: 'Password reset',
    description: '',
    buttonText: 'Save',
    emailField: false,
    codeField: false,
    resendButton: false,
    passwordFields: true,
  };
  public texts = this.resetPasswordtexts;
  public newPasswordFormGroup!: FormGroup;
  public showPasswordOne = false;
  public showPasswordTwo = false;
  public passFieldTypeOne: 'text' | 'password' = 'password';
  public passFieldTypeTwo: 'text' | 'password' = 'password';

  private emailValue: string = '';
  private resetPasswordToken: string = '';
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private route: ActivatedRoute
  ) {
    this.error$ = this.store.select(selectLoginError);
    this.loading$ = this.store.select(selectLoginLoading);
    this.verificationSentTo$ = this.store.select(selectVerificationSentTo);

    // Get email queryparam from URL
    this.emailValue = this.route.snapshot.queryParamMap.get('email') || '';
    this.resetPasswordToken = this.route.snapshot.queryParamMap.get('resetPasswordToken') || '';

    // Set form fields and texts based on URL
    if (window.location.href.includes('forgot-password')) {
      this.texts = this.resetPasswordtexts;
    } else if (window.location.href.includes('code-verification')) {
      this.timeRemaining$ = timer(0, 1000).pipe(
        map((n) => (120 - n) * 1000),
        takeWhile((n) => n >= 0)
      );
      this.texts = this.codeVerificationTexts;
    } else if (window.location.href.includes('reset-password')) {
      this.texts = this.setNewPasswordTexts;
      this.newPasswordFormGroup = this.formBuilder.group(
        {
          password: ['', { nonNullable: true, validators: [Validators.required] }],
          confirmPassword: ['', { nonNullable: true, validators: [Validators.required] }],
        },
        { validator: CustomValidators.MatchingPasswords }
      );
    }
  }

  public ngOnInit(): void {
    this.code.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: string) => {
      if (value.length === 6) {
        this.verifyCode();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public submit(): void {
    if (window.location.href.includes('forgot-password')) {
      this.initForgotPassword();
    } else if (window.location.href.includes('code-verification')) {
      this.verifyCode();
    } else if (window.location.href.includes('reset-password')) {
      this.resetPassword();
    }
  }

  public reSendCode(event: Event): void {
    event.preventDefault();
    if ((event.target as HTMLAnchorElement).classList.contains('disabled')) {
      return;
    }
    this.timeRemaining$ = timer(0, 1000).pipe(
      map((n) => (120 - n) * 1000),
      takeWhile((n) => n >= 0)
    );
    this.store.dispatch(InitForgotPassword({ email: this.emailValue }));
  }

  public toggleShowPassOne(): void {
    this.passFieldTypeOne = this.passFieldTypeOne === 'password' ? 'text' : 'password';
    this.showPasswordOne = !this.showPasswordOne;
  }

  public toggleShowPassTwo(): void {
    this.passFieldTypeTwo = this.passFieldTypeTwo === 'password' ? 'text' : 'password';
    this.showPasswordTwo = !this.showPasswordTwo;
  }

  private initForgotPassword(): void {
    if (this.email.invalid) {
      return;
    }
    this.emailValue = this.email.value;
    this.store.dispatch(InitForgotPassword({ email: this.emailValue }));
  }

  private verifyCode(): void {
    if (this.code.invalid) {
      return;
    }
    this.store.dispatch(VerifyCode({ email: this.emailValue, code: this.code.value }));
  }

  private resetPassword(): void {
    if (this.newPasswordFormGroup.invalid) {
      return;
    }
    this.store.dispatch(
      ResetPassword({
        password: this.newPasswordFormGroup.get('password')?.value,
        resetPasswordToken: this.resetPasswordToken,
      })
    );
  }
}

export class CustomValidators {
  static MatchingPasswords(control: AbstractControl) {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    const currentErrors = control.get('confirmPassword')?.errors;
    const confirmControl = control.get('confirmPassword');

    if (password !== confirmPassword && confirmPassword !== '') {
      confirmControl?.setErrors({ ...currentErrors, not_matching: true });
    } else {
      confirmControl?.setErrors(currentErrors as ValidationErrors);
    }
  }
}
