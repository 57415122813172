import { Component, OnInit } from '@angular/core';
import { UserProfile } from '@auth/interfaces/user-profile.interface';
import { State } from '@auth/store';
import { LoadProfile } from '@auth/store/actions/auth.actions';
import { selectAuthUserProfile } from '@auth/store/selectors/profile.selectors';
import { selectIsAuthenticated } from '@auth/store/selectors/session.selectors';
import { CustomIconService } from '@core/services/custom-icon.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, take } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Cerebro';
  public readonly isAuthenticated$: Observable<boolean>;
  public readonly userProfile$: Observable<UserProfile | null>;

  constructor(private customIconService: CustomIconService, private store: Store<State>) {
    this.customIconService.registerIcons();
    this.isAuthenticated$ = this.store.select(selectIsAuthenticated);
    this.userProfile$ = this.store.select(selectAuthUserProfile);
  }

  ngOnInit(): void {
    combineLatest([this.isAuthenticated$, this.userProfile$])
      .pipe(take(1))
      .subscribe(([isAuthenticated, userProfile]) => {
        if (isAuthenticated) {
          console.log('_RT_ initApp');
          const url = environment.api.split('/');
          Backendless.initApp({
            appId: url[3],
            apiKey: url[4],
            serverURL: `https://${url[2]}`,
          });
          if (!userProfile) {
            this.store.dispatch(LoadProfile());
          }
        }

        // if (isAuthenticated && !userProfile) {
        //   this.store.dispatch(LoadProfile());
        // } else if (isAuthenticated) {
        //   console.log('initRealTime');
        //   const url = environment.api.split('/');
        //   Backendless.initApp({
        //     appId: url[3],
        //     apiKey: url[4],
        //     serverURL: `https://${url[2]}`,
        //   });
        // }
      });
  }
}
