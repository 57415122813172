import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { State } from '@core/store/reducers';
import Backendless from 'backendless';

@Injectable({
  providedIn: 'root',
})
export class RealTimeEventsService {
  constructor(private store: Store<State>) {}

  initRealTime(): void {
    const url = environment.api.split('/');
    Backendless.initApp({
      appId: url[3],
      apiKey: url[4],
      serverURL: `https://${url[2]}`,
    });
  }
}
