import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import {
  LoadCancelReasons,
  LoadCancelReasonsFail,
  LoadCancelReasonsSuccess,
  LoadCountries,
  LoadCountriesFail,
  LoadCountriesSuccess,
  LoadCustomerActionReasons,
  LoadCustomerActionReasonsFail,
  LoadCustomerActionReasonsSuccess,
  LoadHubs,
  LoadHubsFail,
  LoadHubsSuccess,
  LoadOrderCategories,
  LoadOrderCategoriesFail,
  LoadOrderCategoriesSuccess,
  LoadOrderTypes,
  LoadOrderTypesFail,
  LoadOrderTypesSuccess,
  LoadPermissions,
  LoadPermissionsFail,
  LoadPermissionsSuccess,
  LoadSubsidiaries,
  LoadSubsidiariesFail,
  LoadSubsidiariesSuccess,
  SearchOrder,
  StopLoadingHubs,
} from '../actions/data.actions';
import { DataService } from '../../services/data.service';
import { Hub } from '../../interfaces/hub.interface';
import { State } from '../index';
import { Store } from '@ngrx/store';
import { selectAllHubs } from '../selectors/hubs.selectors';
import { selectSubsidiaries } from '../selectors/subsidiaries.selectors';
import { Subsidiary } from '@general-data/interfaces/subsidiary.interface';
import { Permission } from '@general-data/interfaces/permission.interface';
import { selectPermissions } from '../selectors/permissions.selectors';
import { selectCancelReasons } from '../selectors/cancel-reasons.selectors';
import { selectOrderTypes } from '../selectors/order-types.selectors';
import { selectOrderCategories } from '../selectors/order-categories.selectors';
import { WhatToDoService } from '@shared/services/what-to-do.service';
import { GenericScanResp } from '@core/interfaces/scan-response.interface';
import { selectAllCountries } from '../selectors/countries.selectors';
import { selectCustomerActionReasons } from '../selectors/customer-action-reasons.selectors';
import { CancelReason } from '@general-data/interfaces/cancel-reason.interface';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class DataEffects {
  loadHubs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadHubs),
      withLatestFrom(this.store.select(selectAllHubs)),
      // filter(([action, hubs]) => hubs === null),
      mergeMap(([_, hubs]) => {
        if (!hubs) {
          return this.dataService.getHubs().pipe(
            map((hubs: Hub[]) => LoadHubsSuccess({ hubs })),
            catchError(({ error }) => of(LoadHubsFail({ error })))
          );
        } else {
          return of(StopLoadingHubs());
        }
      })
    )
  );

  loadSubsidiaries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadSubsidiaries),
      withLatestFrom(this.store.select(selectSubsidiaries)),
      filter(([_action, subsidiaries]) => subsidiaries === null),
      exhaustMap(([_action, _subsidiaries]) =>
        this.dataService.getSubsidiaries().pipe(
          map((subsidiaries: Subsidiary[]) => LoadSubsidiariesSuccess({ subsidiaries })),
          catchError(({ error }) => {
            return of(LoadSubsidiariesFail({ error }));
          })
        )
      )
    )
  );

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadPermissions),
      withLatestFrom(this.store.select(selectPermissions)),
      filter(([_action, permissions]) => permissions === null),
      exhaustMap(([_action, _permissions]) =>
        this.dataService.getPermissions().pipe(
          map((permissions: Permission[]) => LoadPermissionsSuccess({ permissions })),
          catchError(({ error }) => {
            return of(LoadPermissionsFail({ error }));
          })
        )
      )
    )
  );

  loadCancelReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCancelReasons),
      withLatestFrom(this.store.select(selectCancelReasons)),
      filter(([_action, cancelReasons]) => cancelReasons === null),
      exhaustMap(([_action, _cancelReasons]) => {
        return this.dataService.getCancelReasons().pipe(
          map((cancelReasons: CancelReason[]) => LoadCancelReasonsSuccess({ cancelReasons })),
          catchError(({ error }) => of(LoadCancelReasonsFail({ error })))
        );
      })
    )
  );

  loadOrderTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadOrderTypes),
      withLatestFrom(this.store.select(selectOrderTypes)),
      filter(([_action, orderTypes]) => orderTypes === null),
      exhaustMap(([_action, _orderTypes]) => {
        return this.dataService.getOrderTypes().pipe(
          map((orderTypes: string[]) => LoadOrderTypesSuccess({ orderTypes })),
          catchError(({ error }) => of(LoadOrderTypesFail({ error })))
        );
      })
    )
  );

  loadOrderCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadOrderCategories),
      withLatestFrom(this.store.select(selectOrderCategories)),
      filter(([_action, orderCategories]) => orderCategories === null),
      exhaustMap(([_action, _orderCategories]) => {
        return this.dataService.getOrderCategories().pipe(
          map((orderCategories: string[]) => LoadOrderCategoriesSuccess({ orderCategories })),
          catchError(({ error }) => of(LoadOrderCategoriesFail({ error })))
        );
      })
    )
  );

  searchOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchOrder),
      exhaustMap(({ trackingNumber }) => {
        return this.dataService.searchOrder(trackingNumber).pipe(
          map((scanResponse) => {
            // add urlParam to identify that it is from search
            this.router.navigate([], { relativeTo: this.route, queryParams: { isSearch: true } }).then(() => {
              console.log('scanResponse', scanResponse);
              this.whatToDoService.openWTDDialog(scanResponse as GenericScanResp);
            });
            return { type: 'NO_ACTION' };
          }),
          catchError(({ error }) => {
            const response = {
              status: 'error',
              message: error.message,
            };
            this.whatToDoService.openWTDDialog(response as GenericScanResp);
            return of({ type: 'NO_ACTION' });
          })
        );
      })
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCountries),
      withLatestFrom(this.store.select(selectAllCountries)),
      filter(([_action, countries]) => countries === null),
      exhaustMap(() =>
        this.dataService.getCountries().pipe(
          map((countries) => LoadCountriesSuccess({ countries })),
          catchError(({ error }) => of(LoadCountriesFail({ error })))
        )
      )
    )
  );

  loadCustomerActionReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCustomerActionReasons),
      withLatestFrom(this.store.select(selectCustomerActionReasons)),
      filter(([_action, customerActionReasons]) => customerActionReasons === null),
      exhaustMap((_action) =>
        this.dataService.getCustomerActionReasons().pipe(
          map((customerActionReasons) => LoadCustomerActionReasonsSuccess({ customerActionReasons })),
          catchError(({ error }) => of(LoadCustomerActionReasonsFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store<State>,
    private whatToDoService: WhatToDoService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
}
