import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { State } from '@auth/store';
import { selectAuthUserToken } from '@auth/store/selectors/session.selectors';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private token!: string | null;

  constructor(private store: Store<State>) {
    this.store.select(selectAuthUserToken).subscribe((token: string | null) => (this.token = token));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.token) {
      // eslint-disable-next-line
      let authorizeRequest: HttpRequest<any>;
      if (request.url.includes(environment.api) && !request.url.includes('/services/user/login')) {
        authorizeRequest = request.clone({
          headers: request.headers.set('user-token', this.token),
        });
      } else {
        // Fallback
        authorizeRequest = request;
      }
      return next.handle(authorizeRequest);
    }

    return next.handle(request);
  }
}
