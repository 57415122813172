import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { Login } from '@auth/store/actions/auth.actions';
import { selectLoginError, selectLoginLoading } from '@auth/store/selectors/login.selectors';
import { AuthCredentials, AuthCredentialsForm } from '@auth/interfaces/auth-credentials.interface';
import { Observable } from 'rxjs';
import { selectAuthProfileError } from '@auth/store/selectors/profile.selectors';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLoginComponent {
  public readonly error$: Observable<Error | null>;
  public readonly profileError$: Observable<Error | null>;
  public readonly loading$: Observable<boolean>;
  public loginFormGroup: FormGroup<AuthCredentialsForm> = new FormGroup<AuthCredentialsForm>({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });
  public showPassword = false;
  public passFieldType: 'text' | 'password' = 'password';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<State>
  ) {
    this.error$ = this.store.select(selectLoginError);
    this.profileError$ = this.store.select(selectAuthProfileError);
    this.loading$ = this.store.select(selectLoginLoading);
  }

  public submit(): void {
    if (this.loginFormGroup.invalid) {
      return;
    }
    const values = this.loginFormGroup.value;
    const credentials: AuthCredentials = {
      email: values.email || '',
      password: values.password || '',
    };
    this.store.dispatch(Login({ credentials }));
  }

  public toggleShowPass(): void {
    this.passFieldType = this.passFieldType === 'password' ? 'text' : 'password';
    this.showPassword = !this.showPassword;
  }
}
