import { selectLoginState } from '../index';
import { createSelector } from '@ngrx/store';
import { LoginState } from '../reducers/login.reducer';

export const selectAuthenticated = createSelector(
  selectLoginState,
  (state: LoginState) => state && state.authenticated
);
export const selectLoginLoading = createSelector(selectLoginState, (state: LoginState) => state && state.loading);
export const selectLoginError = createSelector(selectLoginState, (state: LoginState) => state && state.error);
export const selectVerificationSentTo = createSelector(
  selectLoginState,
  (state: LoginState) => state && state.verificationSentTo
);
